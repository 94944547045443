<template>
  <div class="home">
    <div class="cover" :style="`opacity:${coverOpacity};`" v-show="coverOpacity > 0" @click="clickCover">
      <div class="img" :style="`background-image: url('https://img.youtube.com/vi/${videoId}/hqdefault.jpg')`" />
      <div class="play">
        <icon-base icon-name="play" iconColor="#ffffff">
          <icon-play />
        </icon-base>
        <!-- <icon-base v-else-if="playerState == 1" icon-name="pause" iconColor="#ffffff">
          <icon-pause />
        </icon-base>-->
        <!-- <div class="loading" v-else-if="playerState == 3"></div> -->
        <!-- <icon-loading v-else-if="playerState == 3"></icon-loading> -->
      </div>
    </div>
    <div class="video" id="player">
      <!-- <iframe
        id="player"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/D7ygm5OgJsk"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>-->
    </div>
    <lyrics class="lyrics" :type="mode" :lyricsList="lyricsList" @click="clickLyrics"></lyrics>
    <div class="more" v-if="mode == 1" @click="mode = 3">
      <span class="text c_text2 c_color2">{{$t('m.moreLyrics')}}</span>
      <div class="icon">
        <icon-base icon-name="arrow" iconColor="#ffffff">
          <icon-arrow />
        </icon-base>
      </div>
    </div>
    <!-- <div class="options">
      <btn :type="1" :text="$t('m.download')" :mainColor="mainColor" v-on:click="mode = 1" @click="download" />
    </div>-->
    <div class="download-btn" @click="download">
      <div class="download">
        <icon-base icon-name="download" iconColor="#ffffff">
          <icon-download />
        </icon-base>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from './icons/IconBase.vue'
import IconPlay from './icons/IconPlay.vue'
// import IconPause from './icons/IconPause.vue'
import IconArrow from './icons/IconArrow.vue'
import IconDownload from './icons/IconDownload.vue'
// import IconLoading from './icons/IconLoading.vue'
import Lyrics from './lyrics/Lyrics.vue'
// import Btn from './btn/Btn.vue'
export default {
  props: {
    videoId: {
      type: String
    },
    mainColor: {
      type: String
    },
    lyricsList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    IconBase,
    IconPlay,
    IconDownload,
    // IconPause,
    IconArrow,
    // IconLoading,
    Lyrics
    // Btn
  },
  computed: {},
  watch: {
    mode(newValue) {
      if (this.interval) {
        return
      }

      if (newValue == 1) {
        this.interval = setInterval(() => {
          this.coverOpacity += 0.07
          this.videoOpacity -= 0.07
          if (this.coverOpacity >= 1 && this.videoOpacity <= 0) {
            this.coverOpacity = 1
            this.videoOpacity = 0
            clearInterval(this.interval)
            this.interval = ''
          }
        }, 10)
      }

      if (newValue == 2) {
        this.interval = setInterval(() => {
          this.coverOpacity -= 0.07
          this.videoOpacity += 0.07
          if (this.coverOpacity <= 0 && this.videoOpacity >= 0) {
            this.coverOpacity = 0
            this.videoOpacity = 1
            clearInterval(this.interval)
            this.interval = ''
          }
        }, 10)
      }

      if (newValue == 3) {
        this.interval = setInterval(() => {
          this.coverOpacity -= 0.07
          this.videoOpacity -= 0.07
          if (this.coverOpacity <= 0 && this.videoOpacity <= 0) {
            this.coverOpacity = 0
            this.videoOpacity = 0
            clearInterval(this.interval)
            this.interval = ''
          }
        }, 10)
      }
    }
  },
  data() {
    return {
      //1 封面图  2 视频   3 歌词
      mode: 1,
      interval: '',
      coverOpacity: 1,
      videoOpacity: 0,
      player: '',
      playerReady: false,
      playerState: -1,
      openInstallReady: false,
      openInstall: ''
    }
  },
  created() {

    let _this = this
    let data = window.OpenInstall.parseUrlParams() ///openinstall.js中提供的工具函数，解析url中的所有查询参数
    new window.OpenInstall(
      {
        /*appKey必选参数，openinstall平台为每个应用分配的ID*/
        appKey: 'udii2h',
        /*自定义遮罩的html*/
        //mask:function(){
        //  return "<div id='_shadow' style='position:fixed;left:0;top:0;background:rgba(0,255,0,0.5);filter:alpha(opacity=50);width:100%;height:100%;z-index:10000;'></div>"
        //},
        /*OpenInstall初始化完成的回调函数，可选*/
        onready: function () {
          _this.openInstall = this
          /*在app已安装的情况尝试拉起app*/
          this.schemeWakeup()

          // if (isMac || isIOS) {
          //   window.open('https://play.google.com/store/apps/details?id=com.mp4mp3')
          // } else {
          //   this.wakeupOrInstall()
          // }

          _this.openInstallReady = true

          /*用户点击某个按钮时(假定按钮id为downloadButton)，安装app*/
          // var m = this,
          //   button = document.getElementById('downloadButton')
          // button.onclick = function () {
          //   if (isMac || isIOS) {
          //     window.open('https://play.google.com/store/apps/details?id=com.mp4mp3')
          //     return false
          //   }
          //   m.wakeupOrInstall()
          //   return false
          // }
        }
      },
      data
    )
  },
  methods: {
    onPlayerReady() {
      console.log('onPlayerReady')
      this.playerReady = true
    },
    onPlayerStateChange(event) {
      //-1 – 未开始
      // 0 – 已结束
      // 1 – 正在播放
      // 2 – 已暂停
      // 3 – 正在缓冲
      // 5 – 已插入视频
      this.playerState = event.data
      console.log(this.playerState)
    },
    clickLyrics() {
      if (this.mode == 3) {
        this.mode = 1
      }
    },
    clickCover() {
      this.download()
      // if (!this.playerReady) {
      //   return
      // }
      // if (this.playerState != 1) {
      //   this.player.playVideo()
      // } else {
      //   this.player.pauseVideo()
      // }
    },
    download() {
      if (!this.openInstallReady) {
        return
      }
      //OpenInstall初始化时将与openinstall服务器交互，应尽可能早的调用
      /*web页面向app传递的json数据(json string/js Object)，应用被拉起或是首次安装时，通过相应的android/ios api可以获取此数据*/
      // var data = window.OpenInstall.parseUrlParams() ///openinstall.js中提供的工具函数，解析url中的所有查询参数

      let isMac = (function () {
        return /macintosh|mac os x/i.test(navigator.userAgent)
      })()
      let isIOS = (function () {
        return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
      })()

      if (isMac || isIOS) {
        window.open('https://play.google.com/store/apps/details?id=com.mp4mp3')
      } else {
        this.openInstall.wakeupOrInstall()
      }

      // new window.OpenInstall(
      //   {
      //     /*appKey必选参数，openinstall平台为每个应用分配的ID*/
      //     appKey: 'udii2h',
      //     /*自定义遮罩的html*/
      //     //mask:function(){
      //     //  return "<div id='_shadow' style='position:fixed;left:0;top:0;background:rgba(0,255,0,0.5);filter:alpha(opacity=50);width:100%;height:100%;z-index:10000;'></div>"
      //     //},
      //     /*OpenInstall初始化完成的回调函数，可选*/
      //     onready: function () {
      //       /*在app已安装的情况尝试拉起app*/
      //       this.schemeWakeup()

      //       if (isMac || isIOS) {
      //         window.open('https://play.google.com/store/apps/details?id=com.mp4mp3')
      //       } else {
      //         this.wakeupOrInstall()
      //       }

      //       /*用户点击某个按钮时(假定按钮id为downloadButton)，安装app*/
      //       // var m = this,
      //       //   button = document.getElementById('downloadButton')
      //       // button.onclick = function () {
      //       //   if (isMac || isIOS) {
      //       //     window.open('https://play.google.com/store/apps/details?id=com.mp4mp3')
      //       //     return false
      //       //   }
      //       //   m.wakeupOrInstall()
      //       //   return false
      //       // }
      //     }
      //   },
      //   data
      // )
    }
  }
}
</script>

<style lang="less">
.home {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .cover {
    position: absolute;
    top: 0;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      border-radius: 10px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 200%;
    }
    .play {
      position: relative;
      width: 55px;
      height: 55px;
      opacity: 0.9;
      .loading {
        position: absolute;
        width: 20px;
        height: 20px;
        border: 4px solid #e0e0e0;
        border-top-color: #24bf47;
        border-radius: 50%;
        animation: animation 1s linear infinite;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
      @keyframes animation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
  .video {
    position: absolute;
    top: 0;
    width: 100%;
    height: 184px;
    z-index: -3;
    opacity: 0;
  }
  .lyrics {
    height: 0;
    flex: 1;
  }
  .more {
    display: flex;
    flex-direction: row;
    // align-items: center;
    // justify-content: center;
    padding: 18px 0 14px 0;
    .text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .icon {
      width: 20px;
      height: 20px;
      opacity: 0.8;
      display: flex;
      justify-content: center;
    }
  }
  .options {
    width: 280px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .download-btn {
    flex-shrink: 0;
    width: 160px;
    height: 36px;
    border-radius: 50px;
    box-shadow: 0 6px 32px rgb(24 213 156 / 50%);
    background-color: #24bf47;
    margin: 6px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .download {
      width: 16px;
      height: 100%;
    }
  }
}
</style>
