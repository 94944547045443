import Vue from 'vue'
import App from './App.vue'
import 'amfe-flexible'
import './common/styles/index.less'
import VueI18n from 'vue-i18n'

Vue.config.productionTip = false
Vue.use(VueI18n)

const lang = navigator.language || navigator.userLanguage

const i18n = new VueI18n({
  locale: lang || 'zh-CN', // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh-CN': require('./common/lang/zh'), // 中文语言包
    'en-US': require('./common/lang/en') // 英文语言包
  }
})

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app')
