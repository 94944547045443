<template>
  <div class="lyrics-container" @click="click">
    <div
      ref="lyrics-wrapper"
      class="lyrics-wrapper lyrics-mask"
      :class="type == 3?'lyrics-reset':'lyrics-offset'"
      :style="type == 1?'overflow: hidden;':'overflow: scroll;'"
    >
      <div class="lyrics">
        <div class="lyrics-item c_text2_1 c_color2" v-for="(lyrics,index) in lyricsList" :key="index">
          <span class="text">{{lyrics}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    lyricsList: {
      type: Array,
      default: () => {
        return [
          // '多美丽 - G.E.M. 邓紫棋',
          // '词：G.E.M.邓紫棋',
          // '曲：G.E.M.邓紫棋',
          // '制作人 Producer：G.E.M.邓紫棋/T-Ma 马敬恒',
          // '编曲 Arranged by：SILVERSTRIKE/T-Ma 马敬恒/G.E.M.邓紫棋',
          // '和音 Bvox：G.E.M.邓紫棋',
          // '混音 Mixed by：Brian Paturalski',
          // '母带 Mastered by：Randy Merrill @ Sterling Sound',
          // '你是我藏在心中 不愿实现的梦',
          // '像湖面上的星空 一碰就落空',
          // '追不到的月 才是我奔跑的冲动',
          // '抱不住的风 才是最永恒的心动',
          // '我和你的距离 不远也不靠近',
          // '一伸手的距离 多美丽啊 多美丽啊',
          // '多美丽啊 多美丽啊',
          // '世界最美的童话 它名字叫遐想',
          // '你我之间一层纱 千万别戳破它',
          // '留一个遗憾 才让 彼此都不遗忘',
          // '蝴蝶的漂亮 在 于 她留下的花香',
          // '我和你的距离 不远也不靠近',
          // '一伸手的距离 多美丽啊 多美丽啊',
          // '多美丽啊 多美丽啊',
          // '我和你的距离 不远也不靠近',
          // '一伸手的距离 多美丽啊 多美丽啊',
          // '多美丽啊 多美丽啊',
          // '多美丽啊 多美丽啊',
          // '一伸手的距离 多美丽啊 多美丽啊',
          // '多美丽啊 多美丽啊',
          // '世界最美的童话 它名字叫遐想',
          // '你我之间一层纱 千万别戳破它',
          // '留一个遗憾 才让 彼此都不遗忘',
          // '蝴蝶的漂亮 在 于 她留下的花香',
          // '我和你的距离 不远也不靠近',
          // '一伸手的距离 多美丽啊 多美丽啊',
          // '多美丽啊 多美丽啊',
          // '我和你的距离 不远也不靠近',
          // '一伸手的距离 多美丽啊 多美丽啊',
          // '多美丽啊 多美丽啊',
          // '多美丽啊 多美丽啊'
        ]
      }
    },
    type: {
      type: Number,
      //1 封面图   2 视频   3 歌词
      default: 1
    }
  },
  data() {
    return {}
  },
  methods: {
    click() {
      this.$refs['lyrics-wrapper'].scrollTop = 0
      this.$emit('click')
    }
  }
}
</script>

<style lang="less">
.lyrics-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .lyrics-wrapper {
    .lyrics {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px 0;
      .lyrics-item {
        width: 100%;
        flex-shrink: 0;
        text-align: center;
        margin-bottom: 10px;
        .text {
          display: block;
          margin: 0 30px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .lyrics-mask {
    -webkit-mask: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), #fff 10%, #fff 90%, rgba(0, 0, 0, 0));
  }
  .lyrics-offset {
    // transition: all 0.3s ease-in-out 0s;
    // transform: translateY(200px);
    margin-top: 220px;
    transition: margin-top 0.15s ease-out;
  }
  .lyrics-reset {
    // transition: all 0.3s ease-in-out 0s;
    // transform: translateY(0);
    margin-top: 0;
    transition: margin-top 0.25s ease-in;
  }
}
</style>


